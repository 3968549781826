/* Chrome, Safari, Edge, Opera */
/* input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
} */

/* Firefox */
/* input[type=number] {
  -moz-appearance: textfield;
} */

body {
  overflow-x: hidden;
  height: 100%;
}

.ie {
  max-width: 500px;
  width: 100%;
  padding: 16px;
  font-family: 'Nanum Gothic', sans-serif;
  font-size: 1.4rem;
  margin: 16px auto;
  text-align: center;
}

.MuiFormHelperText-root.Mui-error {
  font-weight: 800;
}

@page {
  size: auto;
  margin: 20mm;
}